import React, { useState, useRef, useEffect } from "react"
import { MdMoreVert } from "react-icons/md"

export const MbOpt = ({ children, colors }) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const dropdownRef = useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown()
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener(`mousedown`, handleClickOutside)
    } else {
      document.removeEventListener(`mousedown`, handleClickOutside)
    }

    return () => {
      document.removeEventListener(`mousedown`, handleClickOutside)
    }
  }, [isOpen])

  return (
    <div className="relative" ref={dropdownRef}>

      <button
        className="rounded-md flex items-center justify-center shadow-md focus:outline-none"
        onClick={toggleDropdown}
      >
        <MdMoreVert className="w-7 h-7" style={{ color: colors.white, cursor: `pointer` }} aria-label="show-menu" aria-controls="show-menu" aria-haspopup="true"/>
      </button>

      {/* {isOpen && ( */}
      <div
        className={` absolute right-0 mt-[9px] w-[200px] bg-white rounded-md shadow-lg transition-all 170ms ease-in duration-100  transform ${
          isOpen ? `opacity-100 visible ` : `opacity-0 invisible`
        }`}
      >
        <div className={`p-2 `}>{children}</div>
      </div>

    </div>
  )
}

