// "use client"

// import { Pagination } from "flowbite-react"
// import { useState } from "react"

// export function PaginationTW({ totalPages }) {
//   const [ currentPage, setCurrentPage ] = useState(1)

//   const onPageChange = (e, nxPag) => setCurrentPage(nxPag)

//   return (
//     <div className="flex overflow-x-auto sm:justify-center">
//       <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} showIcons />
//     </div>
//   )
// }
import React from "react"
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md"

const PaginationTW = ({ count, page, onChange, siblingCount = 1, boundaryCount = 1 }) => {
  const handlePageChange = newPage => {
    if (newPage >= 1 && newPage <= count) {
      onChange(null, newPage)
    }
  }

  const generatePages = () => {
    const pages = []
    const start = Math.max(1, page - siblingCount)
    const end = Math.min(count, page + siblingCount)

    for (let i = 1; i <= boundaryCount; i++) {
      if (i < start) {
        pages.push(i)
      }
    }

    if (start > boundaryCount + 1) {
      pages.push(`...`)
    }

    for (let i = start; i <= end; i++) {
      pages.push(i)
    }

    if (end < count - boundaryCount) {
      pages.push(`...`)
    }

    for (let i = count - boundaryCount + 1; i <= count; i++) {
      if (i > end) {
        pages.push(i)
      }
    }

    return pages
  }

  const pages = generatePages()

  return (
    <div className="flex justify-center items-center space-x-2 mt-2 text-[16px]">

      <button
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 1}
        className={`px-3 py-1 rounded-full${
          page === 1 ? ` text-gray-400 cursor-not-allowed` : ` text-gray-700  hover:bg-gray-100`
        }`}
      >
        <MdNavigateBefore />
      </button>

      {pages.map((p, index) => p === `...` ? (
        <span key={index} className="px-3 py-1 text-gray-500">
          {`...`}
        </span>
      ) : (
        <button
          key={index}
          onClick={() => handlePageChange(p)}
          className={`px-3 py-1 rounded-full text-[14px] ${
            p === page ? `bg-zinc-800 text-white hover:bg-zinc-700` : `bg-white text-gray-700 hover:bg-gray-200`
          }`}
        >
          {p}
        </button>
      ))}

      <button
        onClick={() => handlePageChange(page + 1)}
        disabled={page === count}
        className={`px-2 py-2 rounded-full ${
          page === count ? `bg-gray-200 text-gray-400 cursor-not-allowed` : `bg-white text-gray-700 hover:bg-gray-200`
        }`}
      >
        <MdNavigateNext />
      </button>

    </div>
  )
}

export default PaginationTW
