'use client'

import { Logout } from "@/app/(web)/account/_components/menu/Logout"
import {
  MenuContext, MobileContext
} from "@/context"
import { BasketContext } from "@/context/basket/BasketContext"
import { LoadingContext } from "@/context/tools/loading"
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"
import { types } from "@/types"
import { getControlledSections } from "@/utils/functions"
import IconsSVG from "@/utils/icons/IconsSVG"
import { useSession } from "next-auth/react"
import Link from "next/link"
import {
  memo,
  useContext,
  useMemo,
  useRef
} from "react"
import { MbOpt } from "../mobile/MbOpt"
import { SearchBar } from "../search/SearchBar"
import { Currency } from "../select/Currency"
import HomeLink from "./HomeLink"

export const TopMenu = memo(({ ...props }) => {
  const { hostname } = props
  const { isNotBot } = hostname
  const { mobile, webPages, colors, texts: { main_layout: texts }} = hostname
  const { data: login } = useSession()
  const { [types.menuExpanded.openMenu]: open, toggleMenu } = useContext(MenuContext)
  const { [types.menuExpanded.openMenu]: openSwip, [types.mobileMenu.openMenuMobile]: openSwipMobile, toggleOpenSwip, toggleOpenSwipMobile } = useContext(MobileContext)
  const { xsQuery } = useMediaQuerys()
  const mbOptRef = useRef()
  const { handleShowMenu } = useContext(BasketContext)
  const isMobile = useMemo(() => mobile || !xsQuery, [ mobile, xsQuery ])
  const showSection = getControlledSections(webPages, `topBar`)
  const footerState = getControlledSections(webPages, `home`)

  // const oSm = useMemo(() => xsQuery ? openSwip : openSwipMobile, [ xsQuery, openSwip, openSwipMobile ])
  const handleDrawerOpen = e => {
    xsQuery ? toggleMenu() : toggleOpenSwipMobile(!openSwipMobile)
  }
  const { modules } = useContext(LoadingContext)

  return !!texts && (
    <>
      <div className="flex items-center" >
        <IconsSVG
          fillcolor={colors.white}
          wdth={30}
          hght={30}
          path={`/img/menu/${xsQuery ? open ? types._menu.menuOpen : types._menu.menuClose : (xsQuery ? openSwip : openSwipMobile) ? types._menu.menuOpen : types._menu.menuClose}.svg`}
          name={xsQuery ? open ? types._menu.menuOpen : types._menu.menuClose : (xsQuery ? openSwip : openSwipMobile) ? types._menu.menuOpen : types._menu.menuClose}
          className="cursor-pointer"
          onClick={() => {
            handleDrawerOpen()
          }}
        />
        <Link prefetch={isNotBot} href="/" passHref shallow={false} scroll={true} onClick={() => modules.handleLinkLoading(true)}>
          <IconsSVG path={hostname?.business?.logo} name={`logo`}
            fillcolor={colors?.logo?.logoMain} wdth={150}
            hght={38}
            abbr={footerState.nativeLogo ? `native` : ``}/>
        </Link>
      </div>
      {!!showSection?.searchBar && <SearchBar {...props} ismobile="false" />}
      <div className="flex items-center">
        {isMobile && <Currency {...{
          hostname,
          colors,
          textcolor: colors.white
        }} />}
        <div className="flex items-center">
          <HomeLink
            show={isMobile}
            isBasket={true}
            tcl={colors.white}
            {...{
              colors,
              hostname,
              texts,
              login,
              showSection
            }} />
        </div>
        {mobile && isMobile && !!showSection?.login && (
          <>
            <MbOpt {...{ mbOptRef, colors }}>
              <div className="flex flex-col w-full divide-y gap-1">
                <div className="w-full bg-white rounded-md mb-1">
                  <HomeLink
                    show={!isMobile}
                    isBasket={false}
                    tcl={colors.main}
                    {...{
                      colors,
                      hostname,
                      texts,
                      login,
                      showSection
                    }}/>
                </div>
                {login && (
                  <>
                    <Link prefetch={isNotBot} href={`${texts?.profile_orders?.url}`} passHref className="pt-2 pb-1">
                      <div style={{ color: colors.main }} className="font-bold bg-transparent text-sm flex items-center " >
                        <IconsSVG name={`orders`} path={`${types.profileIcons}orders.svg`} size={20} className="cursor-pointer" />
                        {texts?.profile_orders?.text}
                      </div>
                    </Link>
                    <Logout {...{ colors, text: texts?.logout }} small className="pt-2 pb-1"/>
                  </>
                )}
              </div>
            </MbOpt>
          </>
        )}
      </div>
    </>)
})

export default TopMenu
