import { useCallMobile } from "@/hooks/utils/useDimension"
import { handleKeyRandom } from "@/utils/functions"
import { useId, useRef } from "react"
import { useDraggable } from "react-use-draggable-scroll"
import { MdCancel } from "react-icons/md"

export const BoxChips = ({ ...props }) => {
  const {
    chips, mobile, desk, open, width, handleClear, colors
  } = props
  const keyId = useId()
  const ref = useRef(null)
  const { events } = useDraggable(ref)
  const { MOBILE } = useCallMobile(`tablet`, mobile, 900)

  return (
    <div

      // {...{
      //   mobile: MOBILE.toString(),
      //   desk: desk.toString(),
      //   open: open.toString(),
      //   width
      // }}
      {...events}
      ref={ref}
      className="w-full h-auto flex mt-2"
    >
      {chips?.length > 0 && chips?.map((x, i) => (
        <div
          key={handleKeyRandom(keyId, i)}
          className="mt-1 mr-1 h-7 flex items-center "
        >
          <div
            className={`px-3 py-1 flex rounded-full border text-sm bg-gray-100 ${
              x.idx !== null ? `border-gray-400` : `border-green-500`
            }`}
          >
            <span className="font-bold" style={{
              color: colors.secondary
            }}>
              {x.label}
            </span>
            <button
              onClick={() => handleClear(x.referer, x.idx === 0, chips?.length - 1 === i)
              }
              className="ml-2 text-gray-500 hover:text-red-700"
            >
              <MdCancel />
            </button>
          </div>
        </div>
      ))}
    </div>
  )
}
